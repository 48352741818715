<template>
  <div class="search-tag">
    <form @submit.prevent="searchProduct()">
      <div class="tags-container mb-1">
        <v-chip
          v-for="(tagSelected, index) in formData.tags"
          :key="`tag-selected-chip-${index}`"
          class="ma-1"
          color="teal"
          text-color="white"
          small
          label
          close
          @click:close="unSelectTag(index)">
          {{ tagSelected }}
        </v-chip>
      </div>
      <v-row no-gutters>
        <v-col
          cols="6"
          :sm="showCollection ? '3' : '4'"
          :class="`pr-2 ${isMobile ? 'pa-2' : ''}`">
          <v-select
            v-model="formData.brand"
            hide-details
            dense
            solo
            :items="allBrand"
            item-text="text"
            item-value="value"
            :loading="loading">
          </v-select>
        </v-col>
        <v-col
          cols="6"
          :sm="showCollection ? '3' : '4'"
          :class="`pr-2 ${isMobile ? 'pa-2' : ''}`">
          <v-text-field
            v-model.trim="formData.search"
            hide-details
            placeholder="Name, Model"
            dense
            solo
            :loading="loading">
            <template #append>
              <v-icon x-small>
                {{ appendIcon }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col
          v-if="showCollection"
          cols="6"
          sm="3"
          :class="`pr-2 ${isMobile ? 'pa-2' : ''}`">
          <v-autocomplete
            v-model="formData.collection"
            :items="collections"
            item-text="name"
            item-value="id"
            placeholder="Collection"
            filled
            solo
            clearable
            hide-details
            hide-selected
            :loading="loading" />
        </v-col>
        <v-col
          cols="6"
          :sm="showCollection ? '3' : '4'"
          :class="`pr-2 ${isMobile ? 'pa-2' : ''}`"
          class="d-flex align-center">
          <v-autocomplete
            v-model="selectedTags"
            :items="tags"
            :search-input.sync="tagSearch"
            multiple
            placeholder="Tags"
            filled
            solo
            hide-details
            hide-selected
            :loading="loading"
            class="mr-2">
            <template #prepend-inner>
              <v-icon x-small>
                mdi-tag
              </v-icon>
            </template>
            <template #append>
              <span
                class="success--text text-caption font-weight-bold cs-pointer"
                @click="switchTagOperation()">
                {{ formData.tagOperation }}
              </span>
            </template>
            <template #selection>
              <span></span>
            </template>
          </v-autocomplete>
          <v-btn
            :min-width="0"
            :width="40"
            color="secondary"
            small
            type="submit"
            :loading="loading">
            <v-icon x-small>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import ProductCollectionProvider from '@/resources/ProductCollectionProvider'

const ProductAttributeService = new ProductAttributeProvider()
const ProductCollectionService = new ProductCollectionProvider()

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all',
        collection: ''
      })
    },
    appendIcon: {
      type: String,
      default: null
    },
    onlyBrand: {
      type: Boolean,
      default: false
    },
    showCollection: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      tagSearch: '',
      allBrand: [],
      tags: [],
      collections: []
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    selectedTags: {
      get () {
        return this.formData.tags
      },
      set (val) {
        this.formData.tags = val
        this.tagSearch = ''
      }
    }
  },
  async mounted () {
    await this.getAttribute()
  },
  methods: {
    async getAttribute () {
      try {
        this.loading = true

        const { data: brands } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 9999
        })
        const { data: tags } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          limit: 9999,
          sortBy: ['id'],
          sortDesc: [true]
        })

        if (this.showCollection) {
          const { data: collections } = await ProductCollectionService.getAll({
            page: 1,
            limit: 999,
            sortBy: 'createdAt',
            sortOrder: 'desc'
          })

          this.collections = collections.results.map((c) => ({
            id: c.id,
            name: c.name
          }))
        }

        if (this.onlyBrand) {
          this.allBrand = brands.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        } else {
          this.allBrand = [
            {
              text: 'All Brand',
              value: 'all'
            },
            ...brands.results.map((brand) => ({
              text: brand.name,
              value: brand.name
            }))
          ]
        }

        this.tags = tags.results.map((tag) => tag.name)
        this.formData.brand = this.formData.brand ? this.formData.brand : (this.allBrand[0]?.value || null)
      } catch (error) {
        console.error('getAttribute: ', error)
      } finally {
        this.loading = false
      }
    },
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag === this.formData.tags[index])
      if (tagIndex !== -1) {
        this.formData.tags.splice(index, 1)
      }
    },
    searchProduct () {
      this.$emit('on-search', this.formData)
    },
    switchTagOperation () {
      this.formData.tagOperation = this.formData.tagOperation === 'OR' ? 'AND' : 'OR'
    }
  }
}
</script>

<style scoped>
.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.tags-operation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.search-tag .v-input__control {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
.search-tag .v-input__slot {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
</style>
